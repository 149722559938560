import _objectSpread from "/workspace/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.filter.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from 'vuex';
export default {
  name: 'CreationKeyWords',
  props: {
    title: {
      type: undefined,
      required: true
    },
    selectedKeyWords: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      loading: true
    };
  },
  computed: _objectSpread({}, mapGetters({
    allKeyWords: 'keywords/allKeyWords',
    dataKeyWords: 'keywords/dataKeyWords'
  })),
  watch: {
    dataKeyWords: function dataKeyWords() {
      if (this.dataKeyWords.length > 0) {
        this.loading = false;
      }
    }
  },
  beforeMount: function beforeMount() {
    this.$store.dispatch('keywords/findAll');
  },
  methods: {
    selectKeyWord: function selectKeyWord(id) {
      if (!this.selectedKeyWords.some(function (element) {
        return element.id === id;
      })) {
        var keyWord = this.allKeyWords.find(function (item) {
          return item.id === id;
        });
        var selectedList = this.selectedKeyWords;
        selectedList.push(keyWord);
        this.$emit('update:selectedKeyWords', selectedList);
      } else {
        var removedList = this.selectedKeyWords.filter(function (element) {
          return element.id !== id;
        });
        this.$emit('update:selectedKeyWords', removedList);
      }
    },
    removeKeyWord: function removeKeyWord(id) {
      console.log(id);
      var removedList = this.selectedKeyWords.filter(function (element) {
        return element.id !== id;
      });
      this.$emit('update:selectedKeyWords', removedList);
    }
  }
};