//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'FormCheckbox',
  props: {
    id: {
      type: String,
      required: false,
      default: null
    },
    name: {
      type: String,
      required: true
    },
    title: {
      type: undefined,
      required: false,
      default: null
    },
    rules: {
      type: undefined,
      required: false,
      default: ''
    },
    value: {
      type: undefined,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    type: {
      type: String,
      required: false,
      default: 'text'
    },
    description: {
      type: String,
      required: true
    },
    comment: {
      type: String,
      required: false,
      default: null
    },
    toogleCheckbox: {
      type: Function,
      required: true
    }
  },
  data: function data() {
    return {
      fieldValue: this.value
    };
  },
  methods: {
    getValidationState: function getValidationState(_ref) {
      var dirty = _ref.dirty,
          validated = _ref.validated,
          fieldRequired = _ref.required,
          changed = _ref.changed,
          _ref$valid = _ref.valid,
          valid = _ref$valid === void 0 ? null : _ref$valid;
      var result = dirty || validated ? valid : null;
      return !fieldRequired && !changed ? null : result;
    },
    handleInput: function handleInput() {
      this.fieldValue = !this.fieldValue;
      this.toogleCheckbox(this.fieldValue);
    }
  }
};