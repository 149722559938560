import "core-js/modules/es.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'CreationSideBar',
  props: {
    currentStep: {
      type: Number,
      required: true
    }
  },
  data: function data() {
    return {
      stepsData: [{
        icon: 'Edit3Icon',
        colorIcon: 'rgba(61, 71, 87, 1)',
        title: this.$t('#Creation #Steps Basic Informations')
      }, {
        icon: 'InfoIcon',
        colorIcon: 'rgba(52, 120, 246, 1)',
        title: this.$t('#Creation #Steps About Course')
      }, {
        icon: 'UserIcon',
        colorIcon: 'rgba(146, 80, 255, 1)',
        title: this.$t('#Creation #Steps Production')
      }, {
        icon: 'DollarSignIcon',
        colorIcon: 'rgba(236, 163, 22, 1)',
        title: this.$t('#Creation #Steps Pricing and Display')
      }]
    };
  }
};