import "core-js/modules/es.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'FormInputTextArea',
  props: {
    id: {
      type: String,
      required: false,
      default: null
    },
    name: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    title: {
      type: undefined,
      required: true
    },
    rules: {
      type: undefined,
      required: false,
      default: ''
    },
    value: {
      type: undefined,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    comment: {
      type: String,
      required: false,
      default: null
    },
    icon: {
      type: String,
      default: null,
      required: false
    },
    maxlength: {
      type: Number,
      required: false,
      default: 200
    }
  },
  data: function data() {
    return {
      fieldValue: this.value
    };
  },
  methods: {
    getValidationState: function getValidationState(_ref) {
      var dirty = _ref.dirty,
          validated = _ref.validated,
          fieldRequired = _ref.required,
          changed = _ref.changed,
          _ref$valid = _ref.valid,
          valid = _ref$valid === void 0 ? null : _ref$valid;
      var result = dirty || validated ? valid : null;
      return !fieldRequired && !changed ? null : result;
    },
    handleInput: function handleInput() {
      this.$emit('change', this.fieldValue);
      this.$emit('input', this.fieldValue);
    }
  }
};