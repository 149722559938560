import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.filter.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import _ from 'lodash';
export default {
  name: 'CreationFourthStep',
  props: {
    existing: {
      type: [Object, Array],
      required: false,
      default: function _default() {
        return {};
      }
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    nextStep: {
      type: Function,
      required: true
    },
    previousStep: {
      type: Function,
      required: true
    }
  },
  data: function data() {
    return {
      seriesPrice: '100',
      seriesAllowRating: true,
      selectedKeyWords: [],
      selectedOptions: [],
      displayOptions: ['Ebooks', 'ClassHours', 'Certificate', 'Workload', 'Expiration'],
      seriesTermsOfUse: false
    };
  },
  computed: {
    dataFormat: function dataFormat() {
      var format = {
        price: Number(this.seriesPrice),
        keywordsId: this.selectedKeyWords.map(function (keyword) {
          return keyword.id.toString();
        }),
        allowRating: this.seriesAllowRating,
        featuresAllowed: {
          ebooks: this.selectedOptions.some(function (option) {
            return option === 'Ebooks';
          }),
          classHours: this.selectedOptions.some(function (option) {
            return option === 'ClassHours';
          }),
          certificate: this.selectedOptions.some(function (option) {
            return option === 'Certificate';
          }),
          workload: this.selectedOptions.some(function (option) {
            return option === 'Workload';
          }),
          expiration: this.selectedOptions.some(function (option) {
            return option === 'Expiration';
          })
        }
      };
      return format;
    },
    isNextStepDisabled: function isNextStepDisabled() {
      return !(this.seriesPrice !== '' && this.selectedKeyWords[0] && this.selectedOptions[0] && this.seriesTermsOfUse);
    },
    seriesPriceFormat: function seriesPriceFormat() {
      return this.seriesPrice.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    seriesFee: function seriesFee() {
      var price = Number(this.seriesPrice);
      var value = price * (4 / 100);
      var currencyOnUserLocale = Intl.NumberFormat(this.$i18n.locale, {
        style: 'currency',
        currency: 'BRL'
      });
      return currencyOnUserLocale.format(value);
    },
    seriesSale: function seriesSale() {
      var price = Number(this.seriesPrice);
      var fee = price * (4 / 100);
      var value = price + fee;
      var currencyOnUserLocale = Intl.NumberFormat(this.$i18n.locale, {
        style: 'currency',
        currency: 'BRL'
      });
      return currencyOnUserLocale.format(value);
    }
  },
  beforeMount: function beforeMount() {
    var _this$existing;

    if ((_this$existing = this.existing) !== null && _this$existing !== void 0 && _this$existing.id) {
      this.seriesPrice = this.existing.price;
      this.selectedKeyWords = this.existing.keywords;
      this.seriesAllowRating = this.existing.allowRating;
      this.seriesTermsOfUse = true; // eslint-disable-next-line consistent-return

      var selected = _.map(this.existing.featuresAllowed, function (item, key) {
        if (item === true) {
          return key.charAt(0).toUpperCase() + key.slice(1);
        }

        return null;
      });

      this.selectedOptions = _.filter(selected, function (item) {
        return item !== null;
      });
    }
  },
  methods: {
    selectOption: function selectOption(id) {
      if (!this.selectedOptions.some(function (option) {
        return option === id;
      })) {
        this.selectedOptions.push(id);
      } else {
        this.selectedOptions = this.selectedOptions.filter(function (option) {
          return option !== id;
        });
      }
    },
    toggleCheckbox: function toggleCheckbox(value) {
      this.seriesTermsOfUse = value;
    }
  }
};