/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./CreationAuthor.vue?vue&type=template&id=2a4cf83f&scoped=true&"
import script from "./CreationAuthor.vue?vue&type=script&lang=js&"
export * from "./CreationAuthor.vue?vue&type=script&lang=js&"
import style0 from "./CreationAuthor.vue?vue&type=style&index=0&id=2a4cf83f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a4cf83f",
  null
  
)

export default component.exports