//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'CreationAuthor',
  props: {
    name: {
      type: undefined,
      required: true
    },
    bio: {
      type: undefined,
      required: false,
      default: null
    },
    imageUrl: {
      type: undefined,
      required: false,
      default: null
    },
    removeAuthor: {
      type: Function,
      required: true
    }
  }
};