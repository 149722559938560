import "core-js/modules/es.array.filter.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'Accordion',
  props: {
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: false,
      default: null
    },
    appendIcon: {
      type: String,
      default: 'arrow',
      required: false
    },
    selectTags: {
      type: Function,
      required: true
    },
    selectedTags: {
      type: Array,
      default: null,
      required: false
    },
    tagsList: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    },
    subAccordionList: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      opened: false,
      openedSubAccordions: []
    };
  },
  computed: {
    isOpen: function isOpen() {
      return this.opened ? null : {
        'max-height': 0
      };
    },
    nameIconAccordion: function nameIconAccordion() {
      if (this.appendIcon === 'Plus') {
        return this.opened ? 'MinusIcon' : 'PlusIcon';
      }

      return this.opened ? 'ChevronUpIcon' : 'ChevronDownIcon';
    },
    backgroundColor: function backgroundColor() {
      return this.opened ? 'rgba(0, 0, 0, 0.10)' : 'white';
    }
  },
  methods: {
    openAccordion: function openAccordion() {
      this.opened = !this.opened;
    },
    openSubAccordion: function openSubAccordion(id) {
      if (this.openedSubAccordions.some(function (item) {
        return item === id;
      })) {
        this.openedSubAccordions = this.openedSubAccordions.filter(function (item) {
          return item !== id;
        });
      } else {
        this.openedSubAccordions.push(id);
      }
    },
    nameIconSubAccordion: function nameIconSubAccordion(index) {
      return this.openedSubAccordions.some(function (id) {
        return id === index;
      }) ? 'ChevronUpIcon' : 'ChevronDownIcon';
    },
    isOpenSub: function isOpenSub(id) {
      return this.openedSubAccordions.some(function (item) {
        return item === id;
      });
    }
  }
};