//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'CreationPageFooter',
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    lastStep: {
      type: Boolean,
      required: false,
      default: false
    },
    previousStep: {
      type: Function,
      required: true
    },
    nextStep: {
      type: Function,
      required: true
    },
    stepData: {
      type: Object,
      required: false,
      default: function _default() {}
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  }
};