//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'EcommerceCreationPage',
  data: function data() {
    return {
      loading: true,
      series: [],
      currentStep: 0,
      contentData: [{
        title: this.$t('#Creation #Steps Basic Informations')
      }, {
        title: this.$t('#Creation #Steps About Course')
      }, {
        title: this.$t('#Creation #Steps Production')
      }, {
        title: this.$t('#Creation #Steps Pricing and Display')
      }]
    };
  },
  beforeMount: function beforeMount() {
    var _this$$route$query,
        _this = this;

    var query = (_this$$route$query = this.$route.query) === null || _this$$route$query === void 0 ? void 0 : _this$$route$query.series;

    if (query) {
      this.$store.dispatch('series/findById', query).then(function (res) {
        _this.series = res;
        _this.loading = false;
      });
    } else {
      this.loading = false;
    }
  }
};