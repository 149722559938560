//
//
//
//
//
//
//
//
//
export default {
  name: 'TagContainer',
  props: {
    label: {
      type: String,
      required: true
    },
    tagId: {
      type: undefined,
      default: null,
      required: false
    },
    selected: {
      type: Boolean,
      default: false,
      required: false
    },
    selectionFunction: {
      type: Function,
      default: null,
      required: false
    }
  }
};